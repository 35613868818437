@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
   --primary-color-darker: #0051cc;
   --primary-color: #2563eb;
   --primary-color-lighter: #3b82f6;
   --primary-color-lighter-200: #93c5fd;
   --primary-color-lightest: #dbeafe;
   /*blue-600, blue-500, blue-100*/

   --secondary-color-darkest: #475569;
   --secondary-color-darker: #64748b;
   --secondary-color: #94a3b8;
   --secondary-color-lighter: #cbd5e1;
   /*slate-600 slate-500, slate-400, slate-300*/

   --tertiary-color-darker: #eab308;
   --tertiary-color: #facc15;
   --tertiary-color-lighter: #fde047;
   /*yellow-500 yellow-400, yellow-300*/
}

@layer base {
   html {
      --primary-darker: var(--primary-color-darker);
      --primary: var(--primary-color);
      --primary-lighter: var(--primary-color-lighter);
      --primary-lighter-200: var(--primary-color-lighter-200);
      --primary-lightest: var(--primary-color-lightest);

      --secondary-darkest: var(--secondary-color-darkest);
      --secondary-darker: var(--secondary-color-darker);
      --secondary: var(--secondary-color);
      --secondary-lighter: var(--secondary-color-lighter);

      --tertiary-darker: var(--tertiary-color-darker);
      --tertiary: var(--tertiary-color);
      --tertiary-lighter: var(--tertiary-color-lighter);

      font-family: Montserrat, sans-serif;
   }
}

@layer components {
   .filter-blue {
      filter: invert(24%) sepia(75%) saturate(5071%) hue-rotate(211deg)
         brightness(95%) contrast(106%);
   }

   .filter-yellow {
      filter: invert(73%) sepia(25%) saturate(3235%) hue-rotate(4deg)
         brightness(100%) contrast(94%);
   }

   .filter-white {
      filter: invert(97%) sepia(86%) saturate(2%) hue-rotate(257deg)
         brightness(115%) contrast(100%);
   }

   .wrapper {
      @apply mb-24 md:ml-32 lg:ml-48;
   }

   .container {
      @apply mx-auto flex min-h-full w-[85%] max-w-[420px] flex-col items-center md:min-h-0;
   }
   .container.left {
      @apply md:ml-[12%];
   }
}
